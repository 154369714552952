// 暴漏 window.$
import 'jquery'

// import global css
import './assets/styles/global.scss'

// import components
import './components'

// i18n
// import lang from './i18n/languages'
import './i18n/styles/index'
import './i18n/scripts/index'
